<template>

  <div class="container  ">
      <div class=" " style="">
        <img width="90%" height="50%" class="TextLogo justify-center items-center" alt="" src="@/assets/images/footer_logo.png" />
       <!-- <h3 class="TextLogo"> Doctor <span style="color:#0d66d0; text-align: center !important;">4</span> Me</h3> -->
       </div>
       <div >
       <div style="width:80% ;margin:auto ;margin-top:2rem ">
         <p class="P-Text  text-center width:80% ;"  >{{$t("OnlineAppointmentConfirmed")}} </p>
         <p class="P-Text" >{{$t("OnlineAppointmentConfirmedMessage")}}  </p>
            <p class="P-Text2" >{{$t("OnlineAppointmentConfirmedDescribe")}}</p>
       </div>

       <vs-textarea
             v-model="Model.SymptomNote"
              height="93px"
              style="
                border-radius: 16px;
                opacity: 1;
                background: #fefefe 0% 0% no-repeat padding-box;
                width: 80%;
                width:80% ; margin:auto
              "
            >
       </vs-textarea>



       </div>
    <!-- <vs-button class="mt-6 ">{{ $t("Save") }}</vs-button > -->

       <div style="width:80% ;margin:auto ;margin-top:2rem ">
         <p class="P-Text2" >{{$t("OnlineAppointmentConfirmedRequest")}}</p>
       </div>

  </div>


  </template>
  <script>
  import moduleDoctor from "@/store/doctor/moduleDoctor.js";
  import { domain } from "@/gloabelConstant.js";

  export default {
       data() {
      return {
        baseURL: domain,
        Model: {}
      };
    },

      created() {

      if (!moduleDoctor.isRegistered) {
        this.$store.registerModule("DoctorList", moduleDoctor);
        moduleDoctor.isRegistered = true;
          }
            debugger
          var model={};
          model.PatientReservationSessionID = this.$route.params.PatientReservationID;
         this.$store
            .dispatch("DoctorList/getPatientReservation", model)
            .then((res) => {
              if (res.status == 200) {
                     debugger;
                this.Model = res.data.Data[0];
                console.log(this.Model.SymptomNote);
              }
            })
            .catch(() => {
              this.$vs.loading.close();
            });
    },
  }
  </script>
  <style>
  .container
  {

  background: transparent radial-gradient(closest-side at 50% 50%, #004477 0%, #004477 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px;
  opacity: 1;
  text-align: center;
   width: 70%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  }
  .logo{


  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  display: inline-block;

   width: 12REM; padding: .5rem;

  }
  .TextLogo{
    color: #009cff;
    letter-spacing: 0px;
    font-size: 1.8rem;
    font-weight:bold;


  }
  .P-Text{
   color: #FFFFFF;
  margin-bottom:.5rem;
  font-size: 1.2rem;
   letter-spacing: 0px;
   text-align: initial;

  opacity: 1;


  }
  .P-Text2{
   color: #FFFFFF;
  margin-bottom:.5rem;
  font-size: .9rem;
   letter-spacing: 0px;
   text-align: initial;
   margin-top: 1.2rem;

  opacity: 1;


  }
  .text-1xl{
       color: #393939;

  font-size: 1rem;
   letter-spacing: 0px;

  opacity: .5;

  }
  .vs-button{
    background-color: white  !important;
    color: #009CFF;

  }

  </style>
